





























































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import cache from '@/common/cache';
import HeaderBox from '@/components/HeaderBox.vue'
import UploadImage from '@/components/UploadImage.vue'
import ReviewTraces from '@/components/ReviewTraces.vue'

interface SearchParams {
  page: number
  count: number
  car_id: string
  auction_id: string
  store_name: string
  plate_number: string
  auction_from: string
  auction_to: string
  auction_time: string
  submit_time: string
  submit_from: string
  submit_to: string
  status: string
}

interface ApprovedForm {
  remark: string
  image_urls: string[]
}
@Component({
  components: {
    HeaderBox,
    UploadImage,
    ReviewTraces
  }
})
export default class EnterWarehouseManage extends Vue {
  loading: boolean = false
  popoverVisible: boolean = false
  reviewTitle = ''
  approvedDialogVisible: boolean = false
  // uploadUrl: string = '/api/v1/source_handler/upload/image/approval_car_material'
  activeTabName: string = '0'
  tableData: object[] = []
  searchParams: SearchParams = {
    page: 1,
    count: 10,
    car_id: '',
    auction_id: '',
    store_name: '',
    plate_number: '',
    auction_from: '',
    auction_to: '',
    auction_time: '',
    submit_time: '',
    submit_from: '',
    submit_to: '',
    status: '0'
  }
  approvedForm: ApprovedForm = {
    remark: '',
    image_urls: []
  }
  reviewTraces = []
  center_car_id = null
  totalNum: number = 0
  isTapDone = false

  created() {
    this.getCarManagement();
  }

  setTime(value: string, key: string) {
    if (value) {
      this.searchParams[`${key}_from`] = value && value[0];
      this.searchParams[`${key}_to`] = value && value[1];
    } else {
      this.searchParams[`${key}_from`] = this.searchParams[`${key}_to`] = ''
    }
  }

  async getCarManagement () {
    this.loading = true;
    const { code, data } = await this.$rest.carManage.getCarManagement(this.searchParams);
    this.loading = false;
    if (code !== 0) return;
    this.tableData = data.infos;
    this.totalNum = data.total_count;
  }

  async getReviewTraces (id) {
    const { code, data } = await this.$rest.carManage.getReviewTraces(id);
    if (code !== 0) return;
    this.reviewTraces = data.infos;
  }

  handleTabClick (tab) {
    this.searchParams.status = tab.name;
    this.getCarManagement();
  }

  handelSearch () {
    this.searchParams.page = 1;
    this.getCarManagement();
  }

  handleReset () {
    this.searchParams.page = 1;
    this.searchParams.car_id = '',
    this.searchParams.auction_id = '',
    this.searchParams.store_name = '',
    this.searchParams.plate_number = '',
    this.searchParams.auction_from = '',
    this.searchParams.auction_to = '',
    this.searchParams.auction_time = '',
    this.searchParams.submit_time = '',
    this.searchParams.submit_from = '',
    this.searchParams.submit_to = '',
    this.searchParams.status = '0'
    this.getCarManagement();
  }

  handleCurrentChange () {
    this.getCarManagement();
  }

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  handleReviewRecord (id) {
    this.popoverVisible = true;
    this.getReviewTraces(id);
  }

  handleReview (id, type) {
    this.center_car_id = id;
    this.reviewTitle = type === 1 ? '审核通过': '审核驳回';
    this.approvedDialogVisible = true;
  }

  listenChildImage (urls) {
    this.approvedForm.image_urls = urls;
  }

  async handleReviewOk () {
    if (this.approvedForm.remark.length > 200) {
      this.$message.error('内容长度不能超过200个字');
      return;
    }
    this.isTapDone = true;
    let res = null;
    let form: any = Object.assign({}, this.approvedForm);
    form.image_urls = this.formatImages(form.image_urls);
    if (this.reviewTitle === '审核通过') {
      res = await this.$rest.carManage.postReviewPass(this.center_car_id, form);
    } else {
      res = await this.$rest.carManage.postReviewReject(this.center_car_id, form);
    }
    this.isTapDone = false;
    if (res.code !== 0) return;
    this.approvedDialogVisible = false;
    this.getCarManagement();
  }

  @Watch('approvedDialogVisible')
  showApprovedDialogVisible () {
    this.approvedForm.remark = '';
    this.approvedForm.image_urls = [];
  }
}
