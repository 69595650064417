

































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ImagePreview from '@/components/ImagePreview.vue'

@Component({
  components: {
    ImagePreview
  },
  props: {
    reviewTraces: {
      type: Array,
      default: []
    }
  }
})
export default class ReviewTraces extends Vue {
  @Prop({ default: false }) readonly visible!: boolean
  currentImages = []
  currentIndex = 0
  showPreviewWindow = false

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  handleClose () {
    this.$emit('update:visible', false)
  }

  handleLookBigImages (urls, index) {
    this.currentIndex = index;
    this.currentImages = this.formatBigImages(urls);
    this.showPreviewWindow = true;
  }
}
