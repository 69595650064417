

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    imageList: {
      type: Array,
      default() {
        return []
      }
    },
    showCaption: {
      type: Boolean,
      default: true
    },
    imgTitleField: {
      type: String,
      default: 'name'
    },
    imgUrlField: {
      type: String,
      default: 'src'
    }
  }
})
export default class ImagePreview extends Vue {
  @Prop({ default: 0 }) currentIndex
  imageList: ({name: string, src: string})[]
  currentImgIndex = 0

  handleViewPrevPic(i) {
    const len = this.imageList.length
    const nextId = this.currentImgIndex + i
    if (len === 0 || nextId < 0 || nextId >= len) return
    this.currentImgIndex = nextId
  }

  handleClose() {
    this.currentImgIndex = 0
    this.$emit('update:show', false)
    this.$emit('close')
  }

  @Watch('currentIndex')
  updateIndex () {
    this.currentImgIndex = this.currentIndex;
  }
}
